.nav-menu {
  background-color: #11998e;
  width: 12vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  top: 8vh;
  left: 0;
  transition: 400ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding: 8px 0px 8px 16px; */
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* padding: 0 16px; */
  /* border-radius: 4px; */
}

.nav-text a:hover {
  /* background-color: #1a83ff;
     */
  color: rgb(53, 240, 97);
}

.nav-text.seleted {
  background-color: #4e99ee;
}

.selected {
  background-color: #4e99ee;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  height: 90vh;
}

span {
  margin-left: 16px;
}

.logout-btn {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  align-items: center;
  padding: 0.5rem
}

.logout-btn:hover {
  color: rgb(53, 240, 97);
}
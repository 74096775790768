.form-container {
    width: 30rem;
    height: 20rem;
    margin: auto;
    top: 5rem;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 5%;
    background: #1d4350; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #11998e,
      #38ef7d
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #11998e,
      #38ef7d
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  
  .form-group {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  #login-btn {
    font-size: 20px;
    background-color: black;
    color: white;
    border-radius: 8px;
    height: 10%;
    width: 40%;
  }
  
  .submit-group {
    display: flex;
    justify-content: center;
  }
  
  .form-group Label {
    color: white;
  }
  
  .form-group Label[for="title"] {
    font-size: 40px;
    display: flex;
    justify-content: center;
  }
  
  Label[for="forget-link"] {
    display: flex;
    justify-content: center;
  }
  
  #forget-link {
    padding-left: 5px;
  }
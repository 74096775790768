.card-container {
    width: 85vw;
    top: 9vh;
    left: 13vw;
}
.search-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table-row > td {
    vertical-align: middle;
}

.update-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.leads-comment {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.error-alert {
    background-color: #F3BBBB;
    padding: 3vh;
    text-align: center;
    color: rgb(122, 58, 58);
    font-size: 1.5em;
    border-radius: 10px;
}

.action-icon {
    margin: 0.2em;
}
.delete-icon:hover {
    color:red;
    cursor: pointer;
}
.edit-icon:hover {
    color: darkgoldenrod;
    cursor: pointer;
}
.view-detail-icon {
    font-size: 1.2em;
}
.view-detail-icon:hover {
    color: #198754;
    cursor: pointer;
}


@media (max-width: 768px) {
    .update-btn {
        justify-content: center;
    }
    .search-bar {
        margin-bottom: 1rem;
    }
}
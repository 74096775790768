@media only screen and (max-width: 880px) {
  .show {
    display: flex;
    position: absolute;
  }

  .outlet {
    width: 95%;
    justify-content: center;
    position: relative;
  }

  .list-icon {
    display: inline;
  }
}